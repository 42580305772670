const csrftoken = Cookies.get('csrftoken'); // eslint-disable-line no-undef

const cancelLink = document.getElementById("cancel");
if (cancelLink) {
    cancelLink.onclick = function(e) {
        e.preventDefault();
        fetch(cancelLink.href,
            {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'X-CSRFToken': csrftoken
                }
            })
            .then((response) => {
                if (response.ok) {
                    var passwordConfirmation = document.getElementById("password-confirmation");
                    passwordConfirmation.style.display = "none";
                }
            });
    }
}
const resendLink = document.getElementById("resend");
if (resendLink) {
    resendLink.onclick = function(e) {
        e.preventDefault();
        fetch(resendLink.href,
            {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'X-CSRFToken': csrftoken
                }
            })
            .then((response) => {
                if (response.ok) {
                    var confirmationDiv = document.getElementById("email-resent");
                    confirmationDiv.style.display = "flex";
                    setTimeout(function() { confirmationDiv.style.display = "none"; }, 4000);
                }
                else {
                    var passwordConfirmation = document.getElementById("password-confirmation");
                    passwordConfirmation.style.display = "none";
                }
            });
    }
}
